import React, {
  useRef, useCallback, useState, useMemo,
} from 'react';
import {
  Link,
  useIntl,
  changeLocale,
  FormattedMessage,
  navigate,
} from 'gatsby-plugin-intl';
import { useMatch } from '@reach/router';
import './style.css';
import SearchIcon from '@material-ui/icons/Search';
import Button from '@material-ui/core/Button';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { Input } from '@material-ui/core';
import Dropdown from '../Forms/Dropdown/Dropdown';
import logo from '../../assets/logo.svg';
import exit from '../../assets/icons/exit.svg';
import esc from '../../assets/icons/esc.svg';

import Accordion from './Accordion/Accordion';

const LANGUAGES = ['ITA', 'ENG', 'ESP'];

const activeBtnStyle = {
  color: '#D75F2B',
};

export default function NavBar() {
  const intl = useIntl();
  const hamburger = useRef(null);
  const [input, setInput] = useState('');

  const [showSearch, setShowSearch] = useState(true);
  // const hamburgerTop = useRef(null);
  const navMenu = useRef(null);
  const navLink = useRef(null);
  const searchMobile = useRef(null);
  const inputRef = useRef(null);
  const inputRef2 = useRef(null);
  const navButton = useRef(null);

  const isHome = !!useMatch(`/${intl.locale}/`);
  const isFondazione = !!useMatch(`/${intl.locale}/la-fondazione`);
  const isBeato = !!useMatch(`/${intl.locale}/il-beato`);
  const isPromotori = !!useMatch(`/${intl.locale}/i-promotori`);
  const isLogo = !!useMatch(`/${intl.locale}/il-logo`);
  const isCosaFacciamo = !!useMatch(`/${intl.locale}/cosa-facciamo`);
  const isAziende = !!useMatch(`/${intl.locale}/aziende`);
  const isSostienici = !!useMatch(`/${intl.locale}/sostienici`);

  const isChiSiamo = isFondazione || isPromotori || isLogo || isBeato;

  // const hamburger = document.getElementById('hamburger');
  // const navMenu = document.getElementById('nav-menu');
  // const navLink = document.querySelectorAll('.nav-link');

  const toggleMobileMenu = useCallback(() => {
    hamburger.current.classList.add('active');
    // hamburgerTop.current.classList.toggle('active');
    navMenu.current.classList.add('active');
    searchMobile.current.style.display = 'flex';
  }, [hamburger, navMenu, searchMobile]);

  const closeMenu = useCallback(() => {
    hamburger.current.classList.remove('active');
    // hamburgerTop.current.classList.remove('active');
    navMenu.current.classList.remove('active');
    searchMobile.current.style.display = 'none';
  }, [hamburger, navMenu, searchMobile]);

  const handleChange = useCallback((lang) => {
    switch (lang) {
      case 'ENG':
        changeLocale('en');
        break;
      case 'ESP':
        changeLocale('es');
        break;
      default:
        changeLocale('it');
        break;
    }
  }, []);

  const currentLanguageLabel = useMemo(() => {
    switch (intl.locale) {
      case 'en':
        return 'ENG';
      case 'es':
        return 'ESP';
      default:
        return 'ITA';
    }
  }, [intl.locale]);

  const chiSiamoOptions = useMemo(
    () => [
      {
        label: intl.formatMessage({ id: 'navbar_menu_fondazione' }),
        path: '/la-fondazione',
      },
      {
        label: intl.formatMessage({ id: 'navbar_menu_promotori' }),
        path: '/i-promotori',
      },
      {
        label: intl.formatMessage({ id: 'navbar_menu_logo' }),
        path: '/il-logo',
      },
      {
        label: intl.formatMessage({ id: 'navbar_menu_beato' }),
        path: '/il-beato',
      },
    ],
    [intl],
  );

  const showSearchBar = () => {
    setShowSearch(!showSearch);
  };

  const handleChangeInput = (e) => {
    setInput(e);
  };

  const hadleSubmit = useCallback((e, inputREF) => {
    e.preventDefault();
    const res = inputREF.current.value;

    if (res !== '') {
      inputREF.current.value = '';
      navigate(`/${intl.locale === 'it' ? 'cerca' : intl.locale === 'en' ? 'search' : 'cercas'}?search=${res}`);
    }
  }, [intl.locale]);

  return (
    <div className="navContainer">
      <div className="mobileMenu" onClick={toggleMobileMenu}>
        <div ref={hamburger} id="hamburger">
          <span className="bar" />
          <span className="bar" />
          <span className="bar" />
        </div>
        <Button style={{ minWidth: 5 }}>
          <SearchIcon />
        </Button>
      </div>
      <Link to="/" className="logoContainer">
        <img alt="NavbarIMG" src={logo} className="logo" />
      </Link>

      <div className="searchMobile" ref={searchMobile}>
        <div className="internal">
          <div className="leftSearch">
            <div className="searchBox">
              <form
                onSubmit={(e) => {
                  hadleSubmit(e, inputRef2);
                  closeMenu();
                }}
              >
                <Input
                  onChange={(e) => handleChangeInput(e.target.value)}
                  inputRef={inputRef2}
                  fullWidth
                  disableUnderline
                  placeholder={intl.formatMessage({
                    id: 'placeholder_searchbar',
                  })}
                  classes={{ width: '90%' }}
                />
              </form>

            </div>
            <SearchIcon
              className="iconSearch"
              size="large"
              style={{ color: '#5C5C5B' }}
            />
          </div>
          <div
            className="exitMobileMenu"
            onClick={closeMenu}
            onKeyDown={closeMenu}
            role="button"
            tabIndex={0}
          >
            <img alt="exitMobile" src={exit} className="exit" />
          </div>
        </div>

        <Accordion
          id="nav-menu"
          refe={navMenu}
          changeLanguageCbk={handleChange}
        />
      </div>

      <nav className="nav">
        {!showSearch && (
          <div
            style={{
              height: '100%',
              width: '100%',
              margin: 'auto 0',
              display: 'flex',
              justifyContent: 'space-between',
              border: '1px solid rgba(92, 92, 91, 0.2)',
              padding: 2,
            }}
          >
            <div style={{ width: '95%', marginLeft: 30 }}>
              <form
                onSubmit={(e) => hadleSubmit(e, inputRef)}
              >
                <Input
                  onChange={(e) => handleChangeInput(e.target.value)}
                  inputRef={inputRef}
                  fullWidth
                  disableUnderline
                  placeholder={intl.formatMessage({
                    id: 'placeholder_searchbar',
                  })}
                  classes={{ height: 10, fontSize: 15 }}
                />
              </form>
            </div>
            <Button
              onClick={() => showSearchBar()}
              style={{ width: 17, alignSelf: 'center' }}
            >
              <img alt="exit" src={esc} style={{ width: 17, height: 17 }} />
            </Button>
          </div>
        )}
        {showSearch && (
          <div className="leftNav">
            <Dropdown
              title={intl.formatMessage({ id: 'navbar_menu_chi_siamo' })}
              options={chiSiamoOptions}
              areLinks
              buttonStyle={isChiSiamo ? activeBtnStyle : {}}
            >
              <KeyboardArrowDownIcon />
            </Dropdown>
            <Link to="/cosa-facciamo">
              <Button
                ref={navButton}
                className="btnFocused"
                style={{
                  color: isCosaFacciamo ? '#D75F2B' : 'black',
                  fontWeight: 'normal',
                  texttransform: 'uppercase',
                }}
              >
                <FormattedMessage id="navbar_menu_cosa_facciamo" />
              </Button>
            </Link>
            <Link to="/sostienici">
              <Button
                ref={navButton}
                className="btnFocused"
                style={{
                  color: isSostienici ? '#D75F2B' : 'black',
                  fontWeight: 'normal',
                  texttransform: 'uppercase',
                }}
              >
                <FormattedMessage id="navbar_menu_sostienici" />
              </Button>
            </Link>
            <a href="#">
              <Link to="/aziende">
                <Button
                  ref={navButton}
                  className="btnFocused"
                  style={{
                    color: isAziende ? '#D75F2B' : 'black',
                    fontWeight: 'normal',
                    texttransform: 'uppercase',
                  }}
                >
                  <FormattedMessage id="navbar_menu_aziende" />
                </Button>
              </Link>
            </a>
          </div>
        )}
        <div className="rightNav">
          <Button
            onClick={() => showSearchBar()}
            style={{ display: !showSearch && 'none' }}
          >
            <SearchIcon />
          </Button>
          {showSearch && <div className="separator" />}
          <Dropdown
            title={currentLanguageLabel}
            options={LANGUAGES}
            currOptionSelected={currentLanguageLabel}
            handleClick={handleChange}
          >
            <KeyboardArrowDownIcon />
          </Dropdown>
        </div>
      </nav>
    </div>
  );
}
