import React, { useMemo } from 'react';
import { useIntl, Link } from 'gatsby-plugin-intl';
import { map } from 'lodash';
import Socials from '../Socials/Socials';
import './style.css';

export default function Footer() {
  const intl = useIntl();

  const linksToRender = useMemo(() => map(
    [
      { label: intl.formatMessage({ id: 'privacy' }), url: '/privacy-policy' },
      { label: intl.formatMessage({ id: 'dichiarazione_cookie' }), url: '/cookie-policy' },
      // { label: intl.formatMessage({ id: 'footer_label_termini' }), url: '/' },
      // { label: intl.formatMessage({ id: 'footer_label_legale' }), url: '/' },
    ],
    ({ label, url }) => (
      <span
        className="footerLinks"
        key={label}
      >
        <Link style={{ color: 'white' }} to={url}>
          {label}
        </Link>
      </span>
    ),
  ), [intl]);

  return (
    <footer className="footer">
      <div className="footerTitle">
        {intl.formatMessage({ id: 'footer_title' })}
      </div>
      {/* <div className="socials">
        <Socials />
      </div> */}
      <div className="info">
        <div className="infoText" style={{ marginTop: 45 }}>
          Via don Giovanni Minzoni 14 - 10121  - Torino
        </div>
        <a style={{ color: 'white' }} href="mailto:info@fondazionecarloacutis.org" className="infoText underline">
          info@fondazionecarloacutis.org
        </a>
        <div className="infoText" style={{ marginBottom: 20 }}>
          {intl.formatMessage({ id: 'codice_fiscale' })}
          : 97862410012
        </div>
      </div>
      <div className="description">
        {intl.formatMessage({ id: 'footer_description' })}
      </div>
      <div className="links">
        {linksToRender}
      </div>
    </footer>
  );
}
