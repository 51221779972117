import React, { useCallback, useState } from 'react'
import { Link, useIntl, FormattedMessage } from 'gatsby-plugin-intl'
import './style.css'
import { withStyles } from '@material-ui/core/styles'
import MuiAccordion from '@material-ui/core/Accordion'
import MuiAccordionSummary from '@material-ui/core/AccordionSummary'
import MuiAccordionDetails from '@material-ui/core/AccordionDetails'
import Typography from '@material-ui/core/Typography'
import plus from '../../../assets/icons/plus.svg'
import minus from '../../../assets/icons/minus.svg'
import arrR from '../../../assets/icons/arrR.svg'

const MyAccordion = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion)

const AccordionSummary = withStyles({
  root: {
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 56,
    padding: 20,
    display: 'flex',
    width: 'calc(100% - 40px)',
    justifyContent: 'space-between',
    alignItems: 'center',
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary)

const AccordionDetails = withStyles(theme => ({
  root: {
    backgroundColor: 'rgba(0, 0, 0, .03)',
  },
}))(MuiAccordionDetails)

export default function Accordion({ refe, changeLanguageCbk }) {
  const intl = useIntl()
  const [expanded, setExpanded] = useState('0')

  const setIta = useCallback(() => changeLanguageCbk('ITA'), [
    changeLanguageCbk,
  ])
  const setEng = useCallback(() => changeLanguageCbk('ENG'), [
    changeLanguageCbk,
  ])
  const setEsp = useCallback(() => changeLanguageCbk('ESP'), [
    changeLanguageCbk,
  ])

  const selectPanel = useCallback(({ currentTarget }, isExpanded) => {
    if (isExpanded) setExpanded(currentTarget.parentElement.dataset.panelId)
    else setExpanded('0')
  }, [])

  return (
    <div id="nav-menu" ref={refe} style={{ overflow: 'scroll' }}>
      <MyAccordion
        square
        data-panel-id="1"
        expanded={expanded === '1'}
        onChange={selectPanel}
      >
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <div
            style={{
              fontStyle: 'normal',
              fontWeight: 'normal',
              fontSize: '17px',
              lineHeight: '20px',
              color: expanded === '1' ? '#D75F2B' : 'black',
            }}
          >
            <FormattedMessage id="navbar_menu_chi_siamo" />
          </div>
          <img
            alt="accordionIMG"
            src={expanded === '1' ? minus : plus}
            style={{ width: '30px', height: '30px' }}
          />
        </AccordionSummary>
        <AccordionDetails>
          <div style={{ textAlign: 'start' }}>
            <Typography style={{ height: 20, width: '100%', padding: 20 }}>
              {/* <a
                className="anchorMobileMenu"
                style={{ textDecoration: 'none', color: 'black' }}
                href="/la-fondazione"
              >
                <FormattedMessage id="navbar_menu_fondazione" />
              </a> */}
              <Link to="/la-fondazione">
                <FormattedMessage id="navbar_menu_fondazione" />
              </Link>
            </Typography>
            <Typography style={{ height: 20, width: '100%', padding: 20 }}>
              {/* <a
                className="anchorMobileMenu"
                style={{ textDecoration: 'none', color: 'black' }}
                href="/i-promotori"
              >
                <FormattedMessage id="navbar_menu_promotori" />
              </a> */}
              <Link to="/i-promotori">
                <FormattedMessage id="navbar_menu_promotori" />
              </Link>
            </Typography>
            <Typography style={{ height: 20, width: '100%', padding: 20 }}>
              {/* <a
                className="anchorMobileMenu"
                style={{ textDecoration: 'none', color: 'black' }}
                href="/il-logo"
              >
                <FormattedMessage id="navbar_menu_logo" />
              </a> */}
              <Link to="/il-logo">
                <FormattedMessage id="navbar_menu_logo" />
              </Link>
            </Typography>
            <Typography style={{ height: 20, width: '100%', padding: 20 }}>
              {/* <a
                className="anchorMobileMenu"
                style={{ textDecoration: 'none', color: 'black' }}
                href="/il-beato"
              >
                <FormattedMessage id="navbar_menu_beato" />
              </a> */}
              <Link to="/il-beato">
                <FormattedMessage id="navbar_menu_beato" />
              </Link>
            </Typography>
          </div>
        </AccordionDetails>
      </MyAccordion>
      <MyAccordion
        square
        data-panel-id="2"
        expanded={expanded === '2'}
        onChange={selectPanel}
      >
        <Link to="/cosa-facciamo">
          <AccordionSummary>
            <div style={{ color: expanded === 'panel3' ? '#D75F2B' : 'black' }}>
              {intl.formatMessage({ id: 'navbar_menu_cosa_facciamo' })}
            </div>
          </AccordionSummary>
        </Link>
      </MyAccordion>
      <MyAccordion
        square
        data-panel-id="3"
        expanded={expanded === '3'}
        onChange={selectPanel}
      >
        <Link to="/sostienici">
          <AccordionSummary>
            <div style={{ color: expanded === 'panel4' ? '#D75F2B' : 'black' }}>
              <FormattedMessage id="navbar_menu_sostienici" />
            </div>
          </AccordionSummary>
        </Link>
      </MyAccordion>
      <MyAccordion
        square
        data-panel-id="4"
        expanded={expanded === '4'}
        onChange={selectPanel}
      >
        <Link to="/aziende">
          <AccordionSummary>
            <div style={{ color: expanded === 'panel5' ? '#D75F2B' : 'black' }}>
              <FormattedMessage id="navbar_menu_aziende" />
            </div>
          </AccordionSummary>
        </Link>
      </MyAccordion>
      <MyAccordion
        square
        data-panel-id="5"
        expanded={expanded === '5'}
        onChange={selectPanel}
      >
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <div
            style={{
              fontStyle: 'normal',
              fontWeight: 'normal',
              fontSize: '17px',
              lineHeight: '20px',
              color: expanded === '5' ? '#D75F2B' : 'black',
            }}
          >
            <FormattedMessage id="navbar_menu_lingue" />
          </div>
          {expanded === '5' ? (
            <img
              alt="accordionIMG"
              src={arrR}
              style={{
                width: '30px',
                height: '30px',
                transform: 'rotate(-90deg)',
              }}
            />
          ) : (
            <img
              alt="accordionIMG"
              src={arrR}
              style={{
                width: '30px',
                height: '30px',
                transform: 'rotate(90deg)',
              }}
            />
          )}
        </AccordionSummary>
        <AccordionDetails>
          <div style={{ textAlign: 'start' }}>
            <Typography style={{ height: 20, padding: '10px 20px' }}>
              <div
                onClick={setIta}
                className="anchorMobileMenu"
                style={{
                  fontSize: '17px',
                  color: 'black',
                  fontWeight: intl.locale === 'it' ? 'bold' : 'normal',
                }}
              >
                ITA
              </div>
            </Typography>
          </div>
        </AccordionDetails>
        <AccordionDetails>
          <div style={{ textAlign: 'start' }}>
            <Typography style={{ height: 20, padding: '10px 20px' }}>
              <div
                onClick={setEng}
                className="anchorMobileMenu"
                style={{
                  fontSize: '17px',
                  color: 'black',
                  fontWeight: intl.locale === 'en' ? 'bold' : 'normal',
                }}
              >
                ENG
              </div>
            </Typography>
          </div>
        </AccordionDetails>
        <AccordionDetails>
          <div style={{ textAlign: 'start' }}>
            <Typography style={{ height: 20, padding: '10px 20px' }}>
              <div
                onClick={setEsp}
                className="anchorMobileMenu"
                style={{
                  fontSize: '17px',
                  color: 'black',
                  fontWeight: intl.locale === 'es' ? 'bold' : 'normal',
                }}
              >
                ESP
              </div>
            </Typography>
          </div>
        </AccordionDetails>
      </MyAccordion>
      <div style={{ height: '150px' }} />
    </div>
  )
}
