import React, { useState, useRef, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'gatsby-plugin-intl';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  paper: {
    marginRight: theme.spacing(2),
  },
}));

export default function Dropdown({
  title,
  options,
  handleClick,
  currOptionSelected,
  areLinks = false,
  buttonStyle = {},
  children,
}) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => {
      !prevOpen ? anchorRef.current.style.color = '#D75F2B' : anchorRef.current.style.color = 'black';
      return !prevOpen;
    });
  };

  const handleClose = (e, op) => {

    if (anchorRef.current && anchorRef.current.contains(e.target)) {
      return;
    }

    if (op) handleClick(op);
    anchorRef.current.style.color = 'black';
    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <div className={classes.root}>
      <div>
        <Button
          ref={anchorRef}
          aria-controls={open ? 'menu-list-grow' : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
          style={{
            fontWeight: 'normal',
            color: 'black',
            ...buttonStyle,
          }}
        >
          {title}
          {children}
        </Button>
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
          style={{ zIndex: 2 }}
        >
          {({ TransitionProps, placement }) => (
            <Grow
            // eslint-disable-next-line react/jsx-props-no-spreading
              {...TransitionProps}
              style={{

                transformOrigin:
                  placement === 'bottom' ? 'center top' : 'center bottom',
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={(e) => handleClose(e, currOptionSelected)}>
                  <MenuList
                    autoFocusItem={open}
                    id="menu-list-grow"
                    onKeyDown={handleListKeyDown}
                  >
                    {options.length > 0
                      && areLinks === false
                      ? options.map((op, index) => (
                        <MenuItem
                          onClick={(e) => handleClose(e, op)}
                          style={{ fontWeight: 'normal' }}
                          value={op}
                        >
                          {op}
                        </MenuItem>
                      ))
                      : options.map((op) => (
                        <MenuItem style={{ padding: 0 }}>
                          <Link className="NavBarlink" to={op.path} style={{ margin: 0, padding: '10px 20px' }}>
                            {op.label}
                          </Link>
                        </MenuItem>
                      ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </div>
  );
}
